export const constants = {
  DEFAULT_LANG: 'en_US',
  DEFAULT_COUNTRY: 'US',
  ITEM_PATH_ROOT: 'root/responsivegrid',
  ITEM_PATH_HEADER: 'root/header-responsivegrid',
  ITEM_PATH_FOOTER: 'root/footer-responsivegrid',
  PAGE_LOADED: 'Page Loaded',
  PAGE_LOADING: 'Page Loading',
  ROUTING_CONFIG: 'routingConfig',
  APPLICATION_NAME: 'shop',
  EXPERIENCE_SEGMENT: ['NOT Logged In'],
};

export const CARD_OVERVIEW_CONSTANTS = {
  imageDimensionVertical: {
    desktopWidth: 190,
    mobileWidth: 190,
    tabletWidth: 202,
  },
  oneColumn: {
    desktopTop: -30,
    tabletTop: -22,
    mobileTop: -19,
  },
  twoColumn: {
    mobileTop: -26,
  },
  threeColumn: {
    tabletTop: -38,
  },
  stickyCardImageDimension: {
    desktopWidth: 120,
    mobileWidth: 48,
    tabletWidth: 80,
  },
  stickyCardImageVertical: {
    desktopWidth: 256,
    mobileWidth: 216,
    tabletWidth: 168,
  },
};

//Used for Bank Landing Pages only
export const BANK_LANDING_TAB_LIST = [
  {
    tabTitle: 'Personal',
    tabValue: 'personal',
    isActive: true,
  },
  {
    tabTitle: 'Business',
    tabValue: 'business',
  },
];

//Below selector list is used to align items for multiple columns in column container
export const selectorList = [
  {
    selector: 'overview',
    rowCount: 1,
  },
  {
    selector: 'detail',
    rowCount: 1,
  },
  {
    selector: 'list-detail',
    rowCount: 1,
  },
  {
    selector: 'spend',
    rowCount: 3,
  },
  {
    selector: 'points-detail',
    rowCount: 1,
  },
];

export const pointsEarnedCharacterCount = {
  twoColumn: 233,
  threeColumn: 160,
};

export const chaseBoundlessCode = 'CH-PREP';

export enum COBRAND_CARD_TYPE {
  BUSINESSCARDTYPE = 'business',
  PERSONALCARDTYPE = 'personal',
}
export enum COLUMN_COUNT {
  COL_ONE = '1',
  COL_TWO = '2',
}
export enum CHARACTER_COUNT {
  WITH_ICON = 15,
  WITHOUT_ICON = 18,
}
export enum CARD_LAYOUT_TYPE {
  VERTICALLAYOUT = 'vertical-card-layout',
  HORIZONTALLAYOUT = 'horizontal-card-layout',
}
export const VERTICAL = 'vertical';

export enum RESOLVED_URL {
  CHASECREDITCARDURL = 'chase-credit-cards',
  AMEXCREDITCARDURL = 'american-express-credit-cards',
}
export const COBRANDSESSIONDATA = 'cobrandData';
