import dynamic from 'next/dynamic';

const AwardsGallery = dynamic(() => import('./organisms/AwardsGallery/AwardsGallery').then(mod => mod.AwardsGallery));
const CardFeatures = dynamic(() => import('./organisms/CardFeatures/CardFeatures').then(mod => mod.CardFeatures));
const InternationalPopup = dynamic(() =>
  import('./molecules/InternationalPopup/InternationalPopup').then(mod => mod.InternationalPopup)
);
const CobrandContainer = dynamic(() =>
  import('./organisms/CobrandContainer/CobrandContainer').then(mod => mod.CobrandContainer)
);
const PointsEarned = dynamic(() => import('./organisms/PointsEarned/PointsEarned').then(mod => mod.PointsEarned));
const CardDetails = dynamic(() => import('./organisms/CardDetails/CardDetails').then(mod => mod.CardDetails));
const TabComponentContainer = dynamic(() =>
  import('./organisms/TabComponentContainer/TabComponentContainer').then(mod => mod.TabComponentContainer)
);
const PointsDetails = dynamic(() => import('./organisms/PointsDetails/PointsDetails').then(mod => mod.PointsDetails));
const StickyCta = dynamic(() => import('./organisms/StickyCta/StickyCta').then(mod => mod.StickyCta));
const CobrandCarousel = dynamic(() =>
  import('./organisms/CobrandCarousel/CobrandCarousel').then(mod => mod.CobrandCarousel)
);
const CardOverview = dynamic(() => import('./organisms/CardOverview/CardOverview').then(mod => mod.CardOverview));
const AlertBanner = dynamic(() => import('./organisms/AlertBanner/AlertBanner').then(mod => mod.AlertBanner));
const CardComparison = dynamic(() =>
  import('./organisms/CardComparison/CardComparison').then(mod => mod.CardComparison)
);
const DataCell = dynamic(() => import('./molecules/DataCellComponent/DataCellComponent').then(mod => mod.DataCell));

export {
  AwardsGallery,
  CardFeatures,
  CobrandContainer,
  PointsEarned,
  CardDetails,
  InternationalPopup,
  TabComponentContainer,
  PointsDetails,
  StickyCta,
  CobrandCarousel,
  CardOverview,
  AlertBanner,
  CardComparison,
  DataCell,
};

export * from './modules/constants';
export * from './modules/utils';
export * from './context/PageContext';
